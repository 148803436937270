angular.module('fingerink')
    .service('idCardModal', function ($uibModal) {
        this.modalInstance = null;
        this.openModal = function (tokens) {
            this.modalInstance = $uibModal.open({
                animation: false,
                backdrop: 'static',
                size: 'lg',
                windowClass: 'signaturemodal',
                controller: 'idCardModalCtrl',
                controllerAs: 'controller',
                templateUrl: 'web/sign/advanced/modals/idCardModal.tpl.html',
                resolve: {
                    token: function () {
                        return tokens;
                    }
                }
            });

            return this.modalInstance;
        };

    })
    .controller('idCardModalCtrl', function signModalCtrl($rootScope, $scope, $uibModalInstance, $window, $timeout, token, signatureService) {
        var that = this;
        that.step = 1;
        that.files = [];

        //that.selectFile = ()=> $timeout(()=> angular.element('#uploadIdCardInput').click());

        function previewFile(file){
            var reader = new FileReader();
            reader.onload= data=> {
                that.preview= data.target.result;						
                if (that.step < 3) {
                    that.photo1 = that.preview;
                } else {
                    that.photo2 = that.preview;
                }
                $scope.$apply();
            };
            reader.readAsDataURL(file);
        }

        $scope.$watch('controller.files',()=>{
            if(that.files && that.files[0]){
                previewFile(that.files[0]);
                that.step++;
            }
        });

        $scope.$watch('controller.step', function () {
            if (that.step > 4) {
                signatureService.signIdByToken(token, { front: that.photo1, back: that.photo2 }).then(function (response) {
                    if (response.data) {
                        swal("¡Correcto!", "Tu identidad se ha verificado correctamente", "success");
                        $uibModalInstance.close(response.data);
                    } else {
                        swal("Error", "Ha habido un problema al verificar tu identidad, por favor inténtelo de nuevo", "error");
                        that.step = 1;
                    }
                }, function (response) {
                    swal("Error", "Ha habido un problema al verificar tu identidad, por favor inténtelo de nuevo", "error");
                    that.step = 1;
                });
            }
        });
    });
